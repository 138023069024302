import React, {useState, useEffect} from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import img_goalrev from './../../img/Slider_goalRev.jpg';
import img_mines from './../../img/Slider_theminersRun.jpg';
import img_metaverseme from './../../img/Slider_metaverseMe.jpg';
import img_lifesmeta from './../../img/Slider_lifesmeta.jpg';
import img_boc from './../../img/Slider_BattleOfChains.jpg';
import img_netherak from './../../img/Slider_netherak.jpg';
import img_metaclash from './../../img/metaclash_slider.jpg';

const the_data = {
    slider_links: [
        "/case-studies/lifes-meta/",
        "/case-studies/metaverse-me/",
        "/case-studies/metaclash/",
        "/case-studies/the-mines-run/",
        "/case-studies/goal-revolution/",
        "/case-studies/battle-of-chains/",
        "/case-studies/netherak/"
    ],
    slider_images: [
        img_lifesmeta,
        img_metaverseme,
        img_metaclash,
        img_mines,
        img_goalrev,
        img_boc,
        img_netherak
    ],
}


// this class is an ugly hack on react-multi-carousel. The idea is to use
// a different style on the image that is centered. To find the index of 
// this image in the array (declared above) we need to use ugly constants
// depending on screen size (because on mobile we only show 1 centered image,
// and on larger screens we show 3, with one in the middle). The magic numbers
// are 2 on mobile and 5 on larger screens. Don't ask me why.
const PoweredByLAOS = () => { 
    // the index of the image in the middle of the screen
    // on mobile it starts with the first image, on desktop the second (the 
    // middle of the three)
    const [activeIndex, setActiveIndex] = useState(window.innerWidth < 464 ? 0 : 1);
    // the 'magic number' offset used to calculate the index in the image array
    // from the weird slider indices
    const [activeOffset, setActiveOffset] = useState(window.innerWidth < 464 ? 2 : 5);
    
    // used in case the user resizes their screen
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 464){
                setActiveOffset(2);
            }
            else {
                setActiveOffset(5);
            }
          };
        
        window.addEventListener('resize', handleResize);
        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    // called when the slide is changed by swiping or clicking on an arrow
    // uses the weird offsets 
    const changeSlide = (previousSlide, currentSlide, dataSize) => {
        let activeSlide = 0
        // right arrow
        if (previousSlide < currentSlide) 
        {
            activeSlide = (currentSlide - activeOffset >= dataSize) ? (currentSlide - activeOffset - dataSize) : currentSlide - activeOffset
        }
        // left arrow
        else activeSlide = currentSlide + (currentSlide <= dataSize && currentSlide >= activeOffset ? -activeOffset : dataSize - activeOffset);

        setActiveIndex(activeSlide)

    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
    <div className="py-16 background-rising-sun-linear">
        <h2 className="section-title text-3xl md:text-4xl text-center">Powered by LAOS</h2>
        <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored mx-auto" />
        <Carousel
            ssr={true}
            infinite
            centerMode
            // showDots // dots don't work yet
            responsive={responsive}
            dotListClass="custom-dot-list-style"
            className="w-full h-[70%] text-center"
            itemClass="flex justify-center"
            keyBoardControl={true}
            afterChange={(previousSlide, { currentSlide }) => changeSlide(previousSlide, currentSlide, the_data.slider_images.length)}
        >
            {the_data.slider_images.map((img, i) => (
                <div
                    key={`slide-${i}`}
                    className={`relative ${activeIndex !== i ? "w-full" : "w-full"} h-full rounded-[10px] flex justify-center items-center px-4 py-10`}
                >
                    
                    {activeIndex !== i ?
                        <img
                            className='w-[80%] pointer-events-none object-contain rounded-2xl border-2 border-fvpurple opacity-50'
                            src={img}
                            alt="test"
                        />
                        :
                        <a href={the_data.slider_links[i]}>
                            <img
                                key={`slide-img-${activeIndex}`}
                                className='object-contain rounded-2xl border-2 border-fvyellow'
                                src={img}
                                alt="test2"
                            />
                        </a>
                    }
                
                </div>
            )
            )}
        </Carousel>
    </div>
    );
}

export default PoweredByLAOS;